import { useRouter } from "next/router";

import ru from '../../lang/ru.json';
import en from '../../lang/en.json';
import { getSeason } from "../utils/time";


export type LocaleMessagesType = keyof typeof ru;

export interface I18nLocaleFormatData {
    localeAs: (id: LocaleMessagesType) => string;
    localeFrom: (object: { ru: string, en: string }) => string;
    localeDate: (date: Date, options?: LocaleDateOptions) => string;
    localeSeason: (date: Date, id: string) => string;
}

export interface LocaleDateOptions {
    localeMatcher?: 'best fit' | 'lookup';
    weekday?: 'long' | 'short' | 'narrow';
    era?: 'long' | 'short' | 'narrow';
    year?: 'numeric' | '2-digit';
    month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
    day?: 'numeric' | '2-digit';
    hour?: 'numeric' | '2-digit';
    minute?: 'numeric' | '2-digit';
    second?: 'numeric' | '2-digit';
    timeZoneName?: 'long' | 'short';
    formatMatcher?: 'best fit' | 'basic';
    hour12?: boolean;
    timeZone?: string;
}

export const useI18nLocaleFormat = (): I18nLocaleFormatData => {
    const locale = (useRouter() ?? { locale: 'ru' }).locale;

    const localeAs = (id: LocaleMessagesType): string => {
        const localesObject = getLocaleObjectByLocale(locale);

        if (!localesObject) {
            return id;
        }

        return localesObject[id] ?? id;
    }

    const localeFrom = (object: { ru: string, en: string }): string => {
        if (!object || !(object as any).hasOwnProperty(locale)) {
            return '';
        }

        return object[locale];
    }

    const localeDate = (date: Date, options?: LocaleDateOptions): string => {
        const correctDate = new Date(date);

        try {
            return new Intl.DateTimeFormat(locale, options).format(correctDate);
        } catch {
            return '';
        }
    }

    const localeSeason = (date: Date, id: string): string => {
        const season = getSeason(date);
        const year = date.getFullYear();
        return `${localeAs(`${id}.${[ 'WINTER', 'SPRING', 'SUMMER', 'AUTUMN' ][season]}` as any)} ${year}`;
    }

    return {
        localeAs,
        localeFrom,
        localeDate,
        localeSeason
    }
}

const getLocaleObjectByLocale = (locale: string) => {
    switch (locale) {
        case 'ru':
            return ru;
        case 'en':
            return en;
        default:
            return;
    }
}
