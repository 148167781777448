import React, { FC } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useI18nLocaleFormat } from "../../hooks/useI18nLocaleFormat";
import { getHostUrl } from '../../../environment';
import { DraftScope } from "../../data/srm/models/draft.models";

export interface MetaProps {
    title?: string;
    description?: string;
    scope?: DraftScope;
}

const Meta: FC<MetaProps> = ({ title, description, scope }) => {
    const { localeAs } = useI18nLocaleFormat();
    const router = useRouter();

    const isMeetupFlag = scope === "MEETUP";

    const baseTitle = isMeetupFlag ? localeAs('general.meta.title.meetup') : localeAs('general.meta.title');
    const resultTitle = title || baseTitle;
    const resultDescription = description || isMeetupFlag ? localeAs('general.meta.description.meetup') : localeAs('general.meta.description');

    const baseUrl = getHostUrl();
    const route = router.asPath.slice(1);
    const resultUrl = `${baseUrl}${router.locale !== 'ru' ? router.locale + '/' : ''}${route}`;

    const imageUrl = isMeetupFlag ? `${baseUrl}img/CFP_meetups_${router.locale}.jpg` : `${baseUrl}img/CFP_speakers_${router.locale}.jpg`;
    const imageType = 'image/png';
    const imageWidth = '1921';
    const imageHeight = '863';

    const locale = router?.locale === 'ru' ? 'ru_RU' : 'en_EN';

    return (
        <Head>
            <meta charSet="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta httpEquiv="x-ua-compatible" content="ie=edge"/>

            {router.locales?.map(l =>
                <link
                    rel="alternate"
                    hrefLang={l}
                    href={`${baseUrl}${l !== 'ru' ? l + '/' : ''}${route}`}
                    key={l}
                />
            )}

            <title>{resultTitle}</title>

            <meta name="title" content={resultTitle}/>
            <meta name="description" content={resultDescription}/>

            <meta property="og:title" content={resultTitle}/>
            <meta property="og:description" content={resultDescription}/>
            <meta property="og:site_name" content={baseTitle}/>
            <meta property="og:url" content={resultUrl}/>
            <meta property="og:image" content={imageUrl}/>
            <meta property="og:image:type" content={imageType}/>
            <meta property="og:image:width" content={imageWidth}/>
            <meta property="og:image:height" content={imageHeight}/>
            <meta property="og:locale" content={locale}/>
            <meta property="og:type" content="website"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={resultTitle}/>
            <meta name="twitter:description" content={resultDescription}/>
            <meta name="twitter:url" content={resultUrl}/>
            <meta name="twitter:image" content={imageUrl}/>

            <link rel="apple-touch-icon" sizes="180x180" href="/img/favicon/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon/favicon-16x16.png"/>
            <link rel="manifest" href="/img/favicon/site.webmanifest"/>
            <link rel="mask-icon" href="/img/favicon/safari-pinned-tab.svg" color="#e40e20"/>
            <meta name="msapplication-TileColor" content="#e40e20"/>
            <meta name="theme-color" content="#ffffff"/>
        </Head>
    );
};

export default Meta;
